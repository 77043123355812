import { FunctionComponent, useCallback, useRef } from "react";
import { Box, Heading, Text } from "@smile2impress/components";
import classNames from "classnames/bind";

import { sanitizeHtml } from "utils/sanitizeHtml";
import { Icon } from "components/Icons";
import CrossIcon from "components/Icons/icons/24/cross.svg";

import { AccordionItemProps } from "./AccordionItem.types";
import styles from "./AccordionItem.module.scss";

const cx = classNames.bind(styles);

const AccordionItem: FunctionComponent<AccordionItemProps> = ({
    index,
    content,
    active = "",
    onOpen,
    expanded,
}) => {
    const { answer, question } = content;

    const activeItem = useRef<HTMLDivElement>(null);
    const height =
        (active &&
            activeItem &&
            activeItem.current &&
            activeItem.current.clientHeight) ||
        0;

    const handleAccordionClick = useCallback(() => {
        onOpen(index);
    }, [onOpen, index]);

    return (
        <Box className={cx("root")} color="text.primary">
            <div
                className={cx("question", {
                    clickable: !expanded,
                })}
                onClick={expanded ? undefined : handleAccordionClick}
            >
                <Heading as="h3" textStyle="title3" pr="5">
                    {question}
                </Heading>
                {!expanded && (
                    <Icon
                        className={cx("icon", {
                            "icon--active": active,
                        })}
                    >
                        <CrossIcon />
                    </Icon>
                )}
            </div>
            <div
                className={cx("answer")}
                style={{ height: expanded ? "auto " : height }}
            >
                <div
                    className={cx("inner", {
                        inner_open: active || expanded,
                    })}
                    ref={activeItem}
                >
                    <Text
                        textStyle="body"
                        dangerouslySetInnerHTML={{
                            __html: sanitizeHtml(answer),
                        }}
                    />
                </div>
            </div>
        </Box>
    );
};

export default AccordionItem;
