import { Translate } from "next-translate";

import {
    QuestionsContentProps,
    QuestionsParamsProps,
} from "components/Questions";

const getContent = (
    t: Translate,
    { isTeen }: QuestionsParamsProps
): QuestionsContentProps => {
    return {
        title: t("faq:questionsTitle"),
        list: [
            {
                question: t("faq:questionsListItemQuestion1"),
                answer: t("faq:questionsListItemAnswer1"),
            },
            {
                question: t("faq:questionsListItemQuestion2"),
                answer: t("faq:questionsListItemAnswer2"),
            },
            {
                question: t("faq:questionsListItemQuestion3"),
                answer: t("faq:questionsListItemAnswer3"),
            },
            {
                question: t("faq:questionsListItemQuestion4"),
                answer: t("faq:questionsListItemAnswer4"),
            },
        ],
        ...(isTeen && {
            title: t("faq:questionsTitleForTeen"),
            firstImage: {
                desktop: {
                    webp: "/images/teens/boy-desktop.webp",
                    jpeg: "/images/teens/boy-desktop.jpg",
                    media: "(min-width: 1024px)",
                },
            },
            secondImage: {
                desktop: {
                    webp: "/images/teens/show-aligners-desktop.webp",
                    jpeg: "/images/teens/show-aligners-desktop.jpg",
                    media: "(min-width: 1024px)",
                },
            },
        }),
    };
};

export default getContent;
