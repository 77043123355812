import { FC } from "react";
import classNames from "classnames/bind";

import { PictureProps } from "./Picture.types";
import styles from "./Picture.module.scss";

const cx = classNames.bind(styles);

export const Picture: FC<PictureProps> = ({
    mobile,
    desktop,
    desktopL,
    alt,
    className,
    classNameImg = "image",
    width,
    height,
    rounded,
}) => {
    let srcSet = mobile?.jpeg ?? desktop?.jpeg ?? desktopL?.jpeg;

    if (mobile?.png || desktop?.png || desktopL?.png) {
        srcSet = mobile?.png ?? desktop?.png ?? desktopL?.png;
    }

    return (
        <picture className={className}>
            {desktopL && (
                <>
                    {desktopL?.webp && (
                        <source
                            srcSet={desktopL.webp}
                            media={desktopL?.media}
                            type="image/webp"
                        />
                    )}
                    {desktopL.jpeg && (
                        <source
                            srcSet={desktopL.jpeg}
                            media={desktopL?.media}
                            type="image/jpeg"
                        />
                    )}
                    {desktopL.png && (
                        <source
                            srcSet={desktopL.png}
                            media={desktop?.media}
                            type="image/png"
                        />
                    )}
                </>
            )}
            {desktop && (
                <>
                    {desktop?.webp && (
                        <source
                            srcSet={desktop.webp}
                            media={desktop?.media}
                            type="image/webp"
                        />
                    )}
                    {desktop.jpeg && (
                        <source
                            srcSet={desktop.jpeg}
                            media={desktop?.media}
                            type="image/jpeg"
                        />
                    )}
                    {desktop.png && (
                        <source
                            srcSet={desktop.png}
                            media={desktop?.media}
                            type="image/png"
                        />
                    )}
                </>
            )}
            {mobile && (
                <>
                    {mobile.webp && (
                        <source
                            srcSet={mobile.webp}
                            media={mobile?.media}
                            type="image/webp"
                        />
                    )}
                    {mobile.jpeg && (
                        <source
                            srcSet={mobile.jpeg}
                            media={mobile?.media}
                            type="image/jpeg"
                        />
                    )}
                    {mobile.gif && (
                        <source
                            srcSet={mobile.gif}
                            media={mobile?.media}
                            type="image/gif"
                        />
                    )}
                    {mobile.png && (
                        <source
                            srcSet={mobile.png}
                            media={desktop?.media}
                            type="image/png"
                        />
                    )}
                </>
            )}
            <img
                width={width}
                height={height}
                className={cx(classNameImg, {
                    rounded,
                })}
                srcSet={srcSet}
                alt={alt}
            />
        </picture>
    );
};
