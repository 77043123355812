import { Translate } from "next-translate";

import {
    QuestionsContentProps,
    QuestionsParamsProps,
} from "components/Questions";

import defaultContent from "./spain";

const pageContent = (
    t: Translate,
    { isTeen, isSite }: QuestionsParamsProps
): QuestionsContentProps => defaultContent(t, { isTeen, isSite });

export default pageContent;
