import { FunctionComponent, useCallback, useState } from "react";
import classNames from "classnames/bind";

import { AccordionItem } from "../AccordionItem";
import { AccordionListProps } from "./AccordionList.types";
import styles from "./AccordionList.module.scss";

const cx = classNames.bind(styles);

const AccordionList: FunctionComponent<AccordionListProps> = (props) => {
    const { questions, expanded } = props;

    const [activeAccordionItem, setActiveAccordionItem] =
        useState<number | null>(null);

    const handleAccordionOpen = useCallback(
        (index: number) => {
            setActiveAccordionItem(
                index === activeAccordionItem ? null : index
            );
        },
        [activeAccordionItem]
    );

    return (
        <div className={cx("root")}>
            {questions.map((item: any, index: number) => (
                <AccordionItem
                    key={item.question}
                    index={index}
                    content={item}
                    active={index === activeAccordionItem}
                    onOpen={handleAccordionOpen}
                    expanded={expanded}
                />
            ))}
        </div>
    );
};

export default AccordionList;
