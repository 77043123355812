import { ReactChild, ReactFragment } from "react";

import { PictureProps } from "components/Picture";

import { QuestionType } from "./components/AccordionItem/AccordionItem.types";

export enum Colors {
    RED = "#EB5E5B",
}

export enum QuestionsTheme {
    GREY = "grey",
    /* default */
    WHITE = "white",
}

export interface QuestionsParamsProps {
    isTeen?: boolean;
    isSite?: boolean;
}

export interface QuestionsProps extends QuestionsParamsProps {
    content?: QuestionsContentProps;
    className?: string;
    classNameTitle?: string;
    oneColumn?: boolean;
    expanded?: boolean;
}

export interface QuestionsContentProps {
    title: string | ReactChild | ReactFragment;
    list: QuestionType[];

    titleForTeen?: string | ReactChild | ReactFragment;
    description?: string;
    theme?: QuestionsTheme;
    firstImage?: PictureProps;
    secondImage?: PictureProps;
}
