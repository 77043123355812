import { FC } from "react";
import classNames from "classnames/bind";
import useTranslation from "next-translate/useTranslation";
import { Heading } from "@smile2impress/components";

import { useLocationContext } from "context/locationContext";
import { sanitizeHtml } from "utils/sanitizeHtml";
import { Picture } from "components/Picture";

import defaultContent from "./content";
import { AccordionList } from "./components/AccordionList";
import { QuestionsContentProps, QuestionsProps } from "./Questions.types";
import styles from "./Questions.module.scss";

const cx = classNames.bind(styles);

export const Questions: FC<QuestionsProps> = ({
    content,
    className,
    isTeen = false,
    isSite = false,
    oneColumn,
    expanded,
}) => {
    const { t } = useTranslation("faq");
    const { version } = useLocationContext();

    const data =
        content ||
        (defaultContent[version](t, {
            isTeen,
            isSite,
        }) as QuestionsContentProps);
    const { title, list, description, firstImage, secondImage } = data;
    const withImages = firstImage || secondImage;

    return (
        <section
            className={cx("root", className, {
                "root_with-images": withImages,
            })}
        >
            <div
                className={cx("row", oneColumn && "row_block", {
                    "row_with-images": withImages,
                })}
            >
                <div
                    className={cx(
                        "column",
                        withImages
                            ? "column-title-max-width"
                            : "column-without-image"
                    )}
                >
                    <Heading
                        mb={["4", "0"]}
                        zIndex="2"
                        position="relative"
                        textStyle="title1"
                        dangerouslySetInnerHTML={{
                            __html: sanitizeHtml(title as string),
                        }}
                    />

                    {description && (
                        <p className={cx("description")}>{description}</p>
                    )}

                    {firstImage && (
                        <div className={cx("container-first-image")}>
                            <Picture
                                classNameImg={cx("image")}
                                {...firstImage}
                            />
                            <div className={cx("wave-background")} />
                        </div>
                    )}

                    {secondImage && (
                        <div className={cx("container-second-image")}>
                            <Picture
                                classNameImg={cx("image")}
                                {...secondImage}
                            />
                        </div>
                    )}
                </div>
                <div
                    className={cx(
                        "column",
                        withImages
                            ? "column-text-max-width"
                            : "column-without-image"
                    )}
                >
                    <AccordionList questions={list} expanded={expanded} />
                </div>
            </div>
        </section>
    );
};
