import { CountryVersion } from "constants/enum";

import catalan from "./catalan";
import france from "./france";
import germany from "./germany";
import italy from "./italy";
import portugal from "./portugal";
import spain from "./spain";
import uk from "./uk";
import ukraine from "./ukraine";
import us from "./us";

export default {
    [CountryVersion.CATALAN]: catalan,
    [CountryVersion.FRANCE]: france,
    [CountryVersion.GERMANY]: germany,
    [CountryVersion.ITALY]: italy,
    [CountryVersion.PORTUGAL]: portugal,
    [CountryVersion.SPAIN]: spain,
    [CountryVersion.UK]: uk,
    [CountryVersion.UKRAINE]: ukraine,
    [CountryVersion.US]: us,
};
